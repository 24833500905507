body {
    background-image: url('./assets/background-blur.svg'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: #FEAF6F;  
    margin: 0;
    padding: 0;
    min-height: 100vh;
    overflow-x: hidden;
    height: 100%;
  }
  
  .fp-section{
    display:flex !important;
  }
  
  main {
    scroll-snap-type: y mandatory;
  }

  
  /* Fade-in animation */
  .fade-in {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.8s ease, transform 0.8s ease;
  }
  
  .slide-in-left {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.8s ease, transform 0.8s ease;
  }
  
  .zoom-in {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.8s ease, transform 0.8s ease;
  }
  
  /* Initial states before animation */
  .animate-fade {
    opacity: 0;
    transform: translateY(0px);
  }
  
  .animate-slide-left {
    opacity: 0;
    transform: translateX(-20px);
  }
  
  .animate-zoom {
    opacity: 0;
    transform: scale(0.95);
  }
  
  #section0 .layer {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  
  @media (max-width: 768px) {
    .section {
      padding: 0; /* Reset padding and set your desired padding with media queries */
      margin: 0;
      height: 100dvh;
      transform: translateY(-100px);
  }

  .jigsaw-div{
    transform: translateY(-25px);
  }

  #puzzleBox{
    width: 93vw !important;
  }

  .contact-form{
    transform: translateY(-25px);
  }

  .form-card{
    width: 93vw;
  }

  .fp-slide, .fp-slidesContainer {
    height: 100dvh;
    display: flex !important;
    transform: translateY(13px);
    }
  
    .fp-right {
      top: auto !important;
      bottom: 45px !important;
      left: 50%;
      right: auto;
      transform: translateX(-50%) !important;
      right: auto !important;
    }
  
    .fp-right ul {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  
    .fp-right ul li {
      display: inline-block;
    }
  
    .about-section {
      justify-content: flex-start;
    }
  }
  
  .fp-tooltip {
    color: #1F2937 !important;
  }
  
  .background-circle {
    @apply bg-cover bg-no-repeat;
  }
  
  .fp-is-overflow>.fp-overflow {
    overflow-y: hidden;
      overflow-x: hidden;
  }
  
  #fp-nav ul li a span {
    @apply bg-charcoal rounded-full transition duration-200;
  }
  
  #fp-nav ul li.active a span {
    @apply bg-mc-500; 
  }

  @keyframes borderMotion {
    0% {
      transform: translate(5px, 5px);
    }
    50% {
      transform: translate(-5px, -5px);
    }
    100% {
      transform: translate(5px, 5px);
    }
  }
  
  .animate-borderMotion {
    border-color: transparent;
    box-shadow: 0 0 0 4px transparent;
    transition: box-shadow 0.5s ease;
  }
  
  .animate-borderMotion:hover {
    box-shadow: 0 0 0 4px rgba(70, 50, 50, 0.3);
    animation: borderMotion 3s infinite ease-in-out;
    border-radius: inherit;
  }

  .p-inputtext:active {
    border-color: #431309!important;
    box-shadow: 0 0 0 0.02rem black !important;
}

.p-inputtext:focus-visible {
  border-color: #431309!important;
  box-shadow: 0 0 0 0.02rem black !important;
  outline:none;
}
