.jigsaw-puzzle {
	position: relative;
	border: 1px #fff solid;
	height: 600px
}

.jigsaw-puzzle__piece {
	position: absolute;
	cursor: pointer;
	z-index: 1;
	user-select: none;
	border: 1px #cecece solid;
	background-color: #ffb8704f
}

.jigsaw-puzzle__piece.jigsaw-puzzle__piece--solved {
	border: 0px #cecece solid;
	z-index: 0;
	box-shadow: none;
	background-color: #02140a54;
}

.jigsaw-puzzle__piece.jigsaw-puzzle__piece--dragging {
	z-index: 5;
	box-shadow: 0px 3px 3px 3px #00000091;
}

body.puzzle-solved .jigsaw-puzzle__piece {
	border: none;
	background-color: transparent;
}

body.jigsaw-puzzle--solved .jigsaw-puzzle {
	border: none;
	background-color: transparent;
}


@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

.rainbow {
	position: relative;
	z-index: 0;
	width: 100% !important;
	height: 300px;
	border-radius: 10px;
	overflow: hidden;
	padding-bottom: 30px;
	padding-top: 7px;

	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background: #ffffff;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image:
			linear-gradient(var(--color1, #39995300), var(--color1, #39995300)),
			linear-gradient(var(--color2, #39995300), var(--color2, #39995300)),
			linear-gradient(var(--color3, #39995300), var(--color3, #39995300)),
			linear-gradient(var(--color4, #39995300), var(--color4, #39995300));
		animation: rotate 4s linear infinite;
	}

	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 6px;
		top: 6px;
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		background: #ffffff;
		border-radius: 5px;
		animation: opacityChange 3s infinite alternate;
	}
}

@keyframes opacityChange {
	50% {
		opacity: 1;
	}

	100% {
		opacity: .5;
	}
}