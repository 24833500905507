.contact-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 1rem;
  }
 
  
  .contact-form label {
    display: block;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 0.5rem;
  }
  
  .contact-form button {
    padding: 0.75rem;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
  }